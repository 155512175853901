

export function getCsvFromArray(data) {
    const csvResult = []
    data.map(({ columns, values }) => {
        csvResult.push(columns.join(',') + '\n')
        values.map((row) => {
            const csvRow = row.join(',')
            // console.log(row)
            csvResult.push(csvRow + '\n')
        })
    })
    return csvResult.join('')
}