import "./styles.css";
import React, { useState, useEffect, useRef } from "react";
import { Container, Button, Modal, Table, Card } from 'react-bootstrap';
import FileSaver from "file-saver";

import { getCsvFromArray } from "./tools";

export default function DatabaseForm({ database, closeModal }) {
    const [tables, setTables] = useState(getTables())

    function handleSaveClick(e) {
        const tableName = e.target.id
        console.log(tableName)
        const res = database.exec(
            "SELECT * FROM " + tableName
        );
        console.log(res)

        const csvString = getCsvFromArray(res)
        var blob = new Blob([csvString], { type: "text/plain;charset=utf-8" });
        FileSaver.saveAs(blob, tableName + '.csv');

        closeModal()
    }

    function getTables() {
        const res = database.exec(
            "SELECT name FROM sqlite_master WHERE type='table'"
        );
        const tableNames =
            res.length === 0
                ? []
                : res[res.length - 1].values.map(cols => cols[0]);

        const tables = {};

        if (tableNames.length > 0) {

            for (const tableName of tableNames) {
                const res2 = database.exec(
                    `PRAGMA table_info("${tableName}");`
                );
                console.log(res2)
                const colNames =
                    res2.length === 0
                        ? []
                        : res2[res2.length - 1].values;

                tables[tableName] = colNames;
            }
        }
        console.log("tables")
        console.log(tables)
        return tables
    }

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Loaded Tables</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container className="overflow-auto  mt-2 rounded-4" style={{ height: 800, backgroundColor: "rgba(150, 180, 100, 0.1)" }}>
                    {
                        <div className="row" >
                            {Object.keys(tables).sort().map(tableName => (
                                <Card key={tableName} className="col-auto m-2">
                                    <Card.Title>{tableName}</Card.Title>
                                    <Card.Body>
                                        <Table hover>
                                            <tbody>
                                                {tables[tableName].map(col => (
                                                    <tr key={col[0]}><td>{col[1]}</td><td>{col[2]}</td></tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </Card.Body>
                                    <Card.Footer>
                                        <Button
                                            variant="secondary"
                                            id={tableName}
                                            onClick={handleSaveClick}>
                                            Save Table
                                        </Button>
                                    </Card.Footer>
                                </Card>
                            ))}
                        </div>
                    }
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={closeModal}>Close</Button>
            </Modal.Footer>
        </>
    )
}
