import "./styles.css";
import React, { useState, useRef } from "react";
import { Navbar, Container, Button, Modal, FormGroup, FormControl, FormLabel, Row, Col } from 'react-bootstrap';

import FileSaver from "file-saver";
import AddNewCSVForm from "./AddCSVForm";
import DatabaseForm from "./DatabaseForm";
import ResultsTable from "./ResultsTable";

import { getCsvFromArray } from "./tools";

export default function SqlForm({ db }) {
    const [error, setError] = useState(null);
    const [query, setQuery] = useState("")
    const [results, setResults] = useState([]);
    const [showAddModal, setshowAddModal] = useState(false)
    const [showLoadedTablesModal, setShowLoadedTablesModal] = useState(false)
    const queryInputRef = useRef();

    function exec(sql) {
        try {
            // The sql is executed synchronously on the UI thread.
            // You may want to use a web worker here instead
            const res = db.exec(sql)
            setResults(res); // an array of objects is returned
            if (res[0]) {
                setError(res[0].values.length + ' rows fetched');
            } else {
                setError("done")
            }
        } catch (err) {
            // exec throws an error when the SQL statement is invalid
            setError(err);
            setResults([]);
        }
    }

    function handleQueryUpdate(e) {
        console.log(e)
        const newQuery = e.target.value
        setQuery(newQuery)
    }

    function handleQueryClick(e) {
        exec(query)
    }

    function handleLoadedTablesClick() {
        setShowLoadedTablesModal(true)
    }

    function handleAddClick() {
        setshowAddModal(true);
    };

    function handleSaveResultClick() {
        const csvString = getCsvFromArray(results)
        var blob = new Blob([csvString], { type: "text/plain;charset=utf-8" });
        FileSaver.saveAs(blob, "result.csv");
    }

    function closeLoadedTablesModal() {
        setShowLoadedTablesModal(false)
    }

    function closeAddModal() {
        setshowAddModal(false)
    };

    function createTable(data, tname) {
        const cols = data.shift();
        const query = `CREATE TABLE "${tname}" (${cols
            .map(col => `"${col}" TEXT`)
            .join(",")});`;

        console.log(query)
        db.run(query);

        try {
            const insertStmt = db.prepare(
                `INSERT INTO "${tname}" VALUES (${cols.map(val => "?").join(",")})`
            );
            for (const row of data) {
                if (row.length !== cols.length) {
                    // console.log("skipping row", row);
                    continue;
                }
                // console.log(row)
                insertStmt.run(row);
            }
            insertStmt.free();
        } catch (err) {
            db.run(`DROP TABLE IF EXISTS "${tname}"`);
            throw err;
        }
        // setLoaded(true)
        return db;
    };
    return (
        <div className="App">
            <Navbar bg="white" >
                <Container>
                    <div className='col-3 font-sans mt-3'>
                        <p class="mb-0 text-lg font-bold">CSV SQL Deluxe V2</p>
                        <p class="text-gray-700 text-flat">It's great, it's fantastic</p>
                    </div>
                    <FormGroup className="pull-right" style={{ margin: "8px 0" }}>
                        <FormLabel className="error">{(error || "").toString()}</FormLabel>
                        <Button variant="secondary" className='m-2 button-20p' onClick={handleLoadedTablesClick} disabled={false}>
                            Database
                        </Button>
                        <Button variant="danger" onClick={handleAddClick}>
                            Add CSV
                        </Button>
                    </FormGroup>
                </Container>
            </Navbar>
            <div className="grey-bar"></div>
            <Container className="mt-3">
                <Row>
                    <Col>
                        <FormGroup>
                            <FormControl
                                as="textarea"
                                className="rounded-2"
                                ref={queryInputRef}
                                rows={3}
                                onChange={handleQueryUpdate}
                                placeholder="Enter some SQL. No inspiration ? Try “select sqlite_version()”" />
                        </FormGroup>
                    </Col>
                    <Col md={2} >
                        <Row>
                            <Button
                                variant="primary"
                                className="mb-2"
                                onClick={handleQueryClick}
                                disabled={query === ''}>
                                Query
                            </Button>
                        </Row>
                        <Row>
                            <Button
                                variant="success"
                                onClick={handleSaveResultClick}
                                disabled={!results[0]}>
                                Save result
                            </Button>
                        </Row>
                    </Col>
                </Row>
            </Container>
            <Container className="overflow-auto  mt-2 rounded-4 result-container" style={{ height: 700 }}>
                {
                    results.map(({ columns, values }, i) => (
                        <ResultsTable key={i} columns={columns} values={values} />
                    ))
                }
            </Container>

            <Modal
                className="modal-90w"
                show={showAddModal}
                onHide={closeAddModal}>
                <AddNewCSVForm
                    closeModal={closeAddModal}
                    createTable={createTable} />
            </Modal>

            <Modal
                size="xl"
                show={showLoadedTablesModal}
                onHide={closeLoadedTablesModal}>
                <DatabaseForm
                    database={db}
                    closeModal={closeLoadedTablesModal} />
            </Modal>

        </div>
    )
}
