import "./styles.css";
import React from "react";
import { Table } from 'react-bootstrap';


export default function ResultsTable({ columns, values }) {
    return (
        <div>
            <Table striped borderless={true} hover responsive size="sm" style={{ fontSize: "14px" }}>
                <thead >
                    <tr >
                        {columns.map((columnName, i) => (
                            <th key={i}>{columnName}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {
                        // values is an array of arrays representing the results of the query
                        values.map((row, i) => (
                            <tr key={i}>
                                {row.map((value, i) => (
                                    <td key={i}>{value}</td>
                                ))}
                            </tr>
                        ))
                    }
                </tbody>
            </Table>
        </div>
    )
}
