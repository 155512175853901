
import "./styles.css";
import React, { useState, useEffect, useRef } from "react";
import { Navbar, Container, Button, Modal, FormGroup, FormControl, FormLabel, Table, Card } from 'react-bootstrap';
import Papa from "papaparse";

// Required to let webpack 4 know it needs to copy the wasm file to our assets
import sqlWasm from "!!file-loader?name=sql-wasm-[contenthash].wasm!sql.js/dist/sql-wasm.wasm";

export default function AddNewCSVForm({ closeModal, createTable }) {
    const [tableName, setTableName] = useState("")
    const [fileName, setFileName] = useState("")
    const [file, setFile] = useState("")
    const tableNameInputRef = useRef();

    async function parse(csvFile) {
        return new Promise((resolve, reject) => {
            Papa.parse(csvFile, {
                complete: ({ data }) => {
                    resolve(data);
                },
                error: reject
            });
        });
    };

    function handleClose() {
        console.log("CLOSE")
        closeModal()
    }

    function getTableName(fileName) {
        const parts = fileName.split(".");
        if (parts.length < 2) {
            return fileName;
        }

        return parts.slice(0, -1).join(".");
    };

    function handleFile(e) {
        const file = e.target.files[0];
        if (!file) {
            return;
        }
        setFile(file)
        // http://stackoverflow.com/q/4851595/786644
        const fileName = e.target.value.replace("C:\\fakepath\\", "");
        setFileName(fileName)
        const newTableName = getTableName(fileName)
        setTableName(newTableName)
        tableNameInputRef.current.value = newTableName
    }

    function handleTableName(e) {
        const name = e.target.value
        setTableName(name)
    }

    async function handleSubmit(e) {
        const initialStatus = status;

        if (!file) {
            return;
        }
        console.log("handle submit " + fileName)
        const data = await parse(file);
        // console.log(data)
        createTable(data, tableName);
        closeModal()
    };

    return (
        <div>
            <Modal.Header closeButton>
                <Modal.Title>Add New CSV</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <FormGroup className="fullwidth">
                    <FormLabel className="fullwidth">select csvfile
                        <FormControl className="mb-2 fullwidth" type="file" onChange={handleFile} />
                    </FormLabel>
                    <FormLabel>tablename
                        <FormControl ref={tableNameInputRef} type="input" onChange={handleTableName} />
                    </FormLabel>
                </FormGroup>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>Close</Button>
                <Button variant="primary" onClick={handleSubmit} disabled={!tableName}>Load</Button>
            </Modal.Footer>
        </div>
    )
}
